var $ = require('jquery-browserify');
require('jquery-mousewheel')($);
var keycode = require('keycode');
var _ = require('lodash');

var lastPos = 0;
var lastDeltaY = 0;
var i = 0;
var lastI = 0;
var maxPage = 5 -1;
var minHeight = 620;
var scrollBlockerInterval = 400;
var scrollBlocker = false;
var accelerate = false;
var keys = ['up', 'down', 'home', 'end']

function scrollTo(i) {
  // $('#fr-hide-scroll-screen').scrollTop( Math.max($(window).height(), minHeight)*i)
  if (i!=lastI){
    scrollBlocker = true;
    console.log('scrollTo');
    $('#fr-hide-scroll-screen').animate({scrollTop: Math.max($(window).height(), minHeight)*i}, scrollBlockerInterval, 'swing', function(){
      scrollBlocker = false;
      if (Math.abs(lastDeltaY) == 1.0) lastDeltaY = 0;
    } )

  }
  lastI = i;
}

function getPage(){
  return $('#fr-hide-scroll-screen').scrollTop()/Math.max($(window).height(), minHeight)*i;
}

// setInterval(function() {console.log(accelerate)}, 10);
$( function(){
setTimeout (function(){

  $('#custom-scrollbar').css('top', 0)
  $('#custom-scrollbar').css({
    '-webkit-transform': 'translateY(80px)',
    '-ms-transform': 'translateY(80px)',
    'transform': 'translateY(80px)'
  });

  $('#fr-hide-scroll-screen').scroll(function(e){
    let min = 80;

    let screenDiv = $('#fr-hide-scroll-screen');
    let scrollDiv = $('#custom-scrollbar');

    let scrollPerc = screenDiv[0].scrollTop / (screenDiv[0].scrollHeight - screenDiv[0].clientHeight) * 100
    let scrollPos = (screenDiv[0].clientHeight-scrollDiv[0].clientHeight-min) / 100 * scrollPerc + min;
    let cssValue = `translateY(${scrollPos}px)`;
    scrollDiv.css({
      '-webkit-transform': cssValue,
      '-ms-transform': cssValue,
      'transform': cssValue
    });

  });

  $('#fr-hide-scroll-screen').on('mousewheel', function(e){
    e.preventDefault();
  });
  $('#fr-hide-scroll-screen').on('mousewheel', _.debounce(function(e){
    console.log(e.deltaY);
    // if (Math.abs(e.deltaY) > Math.abs(lastDeltaY))
    //   accelerate = true;
    // else
    //   accelerate = false;
    // lastDeltaY = e.deltaY;
    // if (scrollBlocker){
    //   return;
    // }
    // if (!accelerate)
    //  return;
    // if (accelerate){
      if (e.deltaY > 0){
        i = Math.max(i-1,0);
      } else {
        i = Math.min(i+1,maxPage);
      }
    // }
    scrollTo(i);
    // scrollBlocker = true;
    // setTimeout(function(){
    //   scrollBlocker = false;
    // },scrollBlockerInterval);
  },80, {'leading': true, 'trailing': false}));
  $('#fr-hide-scroll-screen').keydown(function(e){
    console.log(keycode(e));
    if (keycode(e) == 'up'){
      i = Math.max(i-1,0);
    } else if (keycode(e) == 'down') {
      i = Math.min(i+1,maxPage);
    } else if (keycode(e) == 'home') {
      i = 0;
    } else if (keycode(e) == 'end') {
      i = maxPage;
    }
    if(keys.indexOf(keycode(e)) != -1){
      e.preventDefault();
      scrollTo(i);
    }
  });

},0)
} )
