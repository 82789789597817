var hsc = require('./fr-hide-scroll');
var psc = require('./fr-page-scroll');
var $ = require('jquery-browserify');

var first = true;
var show_more__counter = 1;

window.validation = function () {
	var valid = true;
	$('input, textarea', this).each(function () {
		if (first)
			$(this).keypress(function () {
				$(this).parents('label').removeClass('label--error')
				first = false;
			});
		if ($(this).attr('data-pattern')) {
			var regex = new RegExp($(this).attr('data-pattern'));
			if (!regex.test($(this).val())) {
				$(this).parents('label').addClass('label--error');
				valid = false;
			}
		}
	});
	return valid;
}

function popup_show(obj) {
	$(obj).addClass('overlay--visible');
	$('body').addClass('no-scroll');
}

function popup_hide(obj) {
	$(obj).removeClass('overlay--visible');
	$('body').removeClass('no-scroll');
}

window.popup_show = popup_show;
window.popup_hide = popup_hide;

$(function () {
	$('.overlay, .popup__close').click(function () {
		popup_hide($('.overlay'));
	});
	$('.overlay__popup').click(function (e) {
		e.stopPropagation();
	});

	$('.menu-collapsed').click(function () {
		$('.menu-container').toggle();
	});

	$('.subscription_fields.label__input--radio').change(function () {
		$('.donation_fields[name="amount"]').val($(this).val().substr(1));
	});
	$('.label__input--radio').change(function () {
		if ($(this).attr('id') == 'custom-donation__radio')
			$('.recurring_checkbox').prop('checked', false).prop('disabled', true);
		else
			$('.recurring_checkbox').prop('disabled', false);
	});
	$('#custom-donation__input').on('input', function () {
		$('.donation_fields[name="amount"]').val($(this).val());
	});
	$('#custom-donation__input').on('focus', function (event) {
		$("#custom-donation__radio").prop('checked', true).change();
	});
	$('#custom-donation__radio').change(function () {
	});
	$('.subscription_fields.label__input--radio:checked').change();
	$('.paypal_form').submit(function () {
		if ($('.recurring_checkbox').prop('checked')) {
			$('.subscription_fields').prop('disabled', false);
			$('.donation_fields').prop('disabled', true);
		} else {
			$('.subscription_fields').prop('disabled', true);
			$('.donation_fields').prop('disabled', false);
		}
	});
	$('.js-onchange-submit').change(function () {
		$(this).parents('form').submit();
	});

	var tab = require('./tab');

	$('.js__search-show_more').on('loaded_data', function () {
		$('#js__search_count').html(($('#js__search_count').data('msg') || '').replace('{count}', $('.js__show_more_content').children('*').length))
	});
	$('.js__show_more').each(function () {
		if ($(this).data('lastpage') <= show_more__counter)
			$(this).hide();
		$(this).click(function (event) {
			if ($(this).data('lastpage') <= ++show_more__counter)
				$(this).hide();
			$.ajax({
				url: window.location,
				data: { page: show_more__counter },
				dataType: 'html',
			}).done(function (data) {
				$('.js__show_more_content').append(data);
				$('.js__show_more').trigger('loaded_data');
			});
			event.preventDefault();
			return false;
		})
	})
	$('.js__show_more').trigger('loaded_data');

	var field = $('#signup__email-input');
	var error = $('#signup__email-error');
	field.on('keypress', function () {
		error.hide();
	});
	$('#signup__consent-input').on('change', function (event) {
		$('#signup__submit').prop('disabled', !event.target.checked);
	});
	$('#signup__form').on('submit', function (event) {
		// email validation
		var regexp = new RegExp(/.+@.+\..+/);
		if (regexp.test(field.val()) !== true) {
			error.show();
		} else {
			$('.subscribe-form').hide();
			$('.subscribe-success').show();
			$.ajax({
				url: '/email_subscribe',
				headers: {
					'X-CSRF-TOKEN': window.Laravel.csrfToken
				},
				data: { email: field.val() },
				type: 'POST',
				success: function (result) { }
			}
			);
		}

		event.preventDefault();
		return false;
	});
});
window.hide_scroll = hsc.default;
// $( function(){
//   setTimeout (function(){
//     var myScroll = new IScroll('#fr-hide-scroll-screen',
//         // snap: 'section',
//         // mouseWheel: true,
//         // scrollbars: true,
//         // disablePointer: true
//         disableMouse: true,
//   			mouseWheel: true,
//   			invertWheelDirection: false,
//   			scrollX: !false,
//   			scrollY: true,
//   			bounce: true,
//   			snap: 'section',
//   			// eventPassthrough: settings.vertical ? 'horizontal' : true,
//   			probeType: 3,
//   			tap: false,
//   			click: false,
//   			snapSpeed: 350,
//     });
//   },0)
// });
