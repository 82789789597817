var $ = require('jquery-browserify');

exports.default = function(){
  $('body').append(`
    <style>
    html, body {
      overflow: hidden;
      width: 100%;
      height: 100%;
    }
    #fr-hide-scroll-screen {
      height: 100%;
      overflow: auto;
      position: relative;
    }
    </style>
    `);

  $('body').wrapInner('<div id="fr-hide-scroll-screen"><div id="fr-hide-scroll-content"></div></div>');
  let scroll_width = ($('#fr-hide-scroll-screen').width() - $('#fr-hide-scroll-content').width()) + 20;
  $('#fr-hide-scroll-screen').css('marginRight', -scroll_width).attr("tabindex",-1).focus();
}
