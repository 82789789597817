var root = document.querySelector('.tab');
var contentsRoot = document.querySelector('.tab__contents');
NodeList.prototype.forEach = Array.prototype.forEach;
if (root){

var clickFn = function(evt, element, index){
root.querySelectorAll('.tab__nav-item').forEach(function(e){
  e.className = e.className.replace(/\btab__nav-item--active/, '');
});
if (contentsRoot){
  contentsRoot.querySelectorAll('.tab__content').forEach(function(e, i){
    e.className = e.className.replace(/\btab__content--active/, '');
    if (index === i){
      e.className += ' tab__content--active';
    }
  });
}
element.className += ' tab__nav-item--active';
};
root.querySelectorAll('.tab__nav-item').forEach(function(element, index){
element.addEventListener('click', function(evt){clickFn(evt, element, index)})
element.addEventListener('touchend', function(evt){clickFn(evt, element, index)})

if (element.className.search(/\btab__nav-item--active/) !== -1){
  clickFn({}, element, index);
}
})
}
